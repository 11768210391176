export const shortQueries = [

    {screen: true,
    minWidth: 320,
    maxHeight: 480, 
    },
    {screen: true,
    minWidth: 320,
    maxHeight: 480,
    orientation: 'landscape',
    },
    {screen: true,
    minWidth: 320,
    maxHeight: 480,
    orientation: 'portrait'
    },
    {screen: true,
    minWidth: 320,
    maxHeight: 568,
    orientation: 'landscape',
    },
    {screen: true,
    minWidth: 320,
    maxHeight: 568,
    orientation: 'portrait',
    }
];

export const narrowQueries = [
    {screen: true,
    minWidth: 321, 
    },
    {screen: true,
    maxWidth: 320,
    },
    {screen: true,
    minWidth: 375,
    maxHeight: 667,
    orientation: 'landscape',
    },
    {screen: true,
    minWidth: 375,
    maxHeight: 667,
    orientation: 'portrait',
    },
    {screen: true,
    minWidth: 414,
    maxHeight: 736,
    orientation: 'landscape',
    },
    {screen: true,
    minWidth: 414,
    maxHeight: 736,
    orientation: 'portrait'
    },
    {screen: true,
    minWidth: 375,
    maxHeight: 812,
    orientation: 'landscape'
    },
    {screen: true,
    minWidth: 375,
    maxHeight: 812,
    orientation: 'portrait',
    },
    {screen: true,
    minWidth: 414,
    maxHeight: 896,
    orientation: 'landscape',
    },
    {screen: true,
    minWidth: 414,
    maxHeight: 896,
    orientation: 'portrait',
    },
    {screen: true,
    minWidth: 320,
    maxHeight: 640,
    orientation: 'landscape'
    },
    {screen: true,
    minWidth: 320,
    maxHeight: 640,
    orientation: 'portrait',
    },
    {screen: true,
    minWidth: 320,
    maxHeight: 640,
    orientation: 'landscape',
    },
    {screen: true,
    minWidth: 320,
    maxHeight: 640,
    orientation: 'portrait'
    },
    {screen: true,
    minWidth: 360,
    maxHeight: 640,
    orientation: 'landscape'
    },
    {screen: true,
    minWidth: 360,
    maxHeight: 640,
    orientation: 'portrait'
    }
]

export const NormalQueries = [
    {screen: true,
    minWidth: 768,
    maxHeight: 1024,
    },
    {screen: true,
    minWidth: 768,
    maxHeight: 1024,
    orientation: 'landscape',
    },
    {screen: true,
    minWidth: 768,
    maxHeight: 1024,
    orientation: 'portrait',
    },
    {screen: true,
    minWidth: 768,
    maxHeight: 1024,
    orientation: 'portrait',
    },
    {screen: true,
    minWidth: 768,
    maxHeight: 1024,
    orientation: 'landscape'
    },
    {screen: true,
    minWidth: 768,
    maxHeight: 1024,
    orientation: 'portrait',
    },
    {screen: true,
    minWidth: 1224
    },
    {screen: true,
    minWidth: 1824
    }
];