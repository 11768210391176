import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import React from "react";

const PanZoom = ({ short }) => {
  return (
    <>
      <Box sx={{ px: 2, pb: 0 }}>
        <Typography
          style={short ? { width: 225, fontSize: 12 } : { fontSize: 14 }}
        >
          Drag the map using your fingers or cursor and use the zoom tools{" "}
          <FontAwesomeIcon icon={faSearchPlus} /> to position your property.
          Click start to begin estimating.
        </Typography>
        <Box sx={{ textAlign: "center" }}>
          {short ? null : (
            <img
              src="/measurit-pan-zoom.gif"
              alt="animate pan zoom"
              style={{ width: 220 }}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default PanZoom;
