import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faUndoAlt,
  faTrashAlt,
  faUnlock, 
  faTimes,
  faSave
} from '@fortawesome/free-solid-svg-icons'

/*
  This component renders custom control buttons over the
  estimator's HTML canvas for perimeter calculation.

  This component takes the following props:

  * color
  * handleUndoButton
  * handleClearButton
  * handleUnlockMapButton
  * handleSaveButton
*/

export default function PerimeterDrawControls(props) {

  // Build styles for buttons
  const buttonStyle = {
    width: "60px",
    height: "60px",
    backgroundColor: props.color.button,
    border: "none",
    borderRadius: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "30px",
    color: props.color.iconActive
  };

  return (
    <Fragment>
      {/* Top Controls */}
      <div
        style={{
          position: "absolute",
          top: "20px",
          left: "10px",
          zIndex: 99
        }}
      >
        <button 
          aria-label="Unlock map"
          type="button" 
          onClick={props.handleUnlockMapButton}
          style={{...buttonStyle, ...{width: 30, height: 30, borderRadius: 15, fontSize: 20}}}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "160px",
          right: "20px",
          display: "flex",
          flexDirection: "column",
          zIndex: 99
        }}
      >
        <button 
          aria-label="Undo"
          type="button" 
          onClick={props.handleUndoButton}
          style={{
            ...buttonStyle,
            marginBottom: "10px"
          }}
        >
          <FontAwesomeIcon icon={faUndoAlt} />
        </button>
        <button 
          aria-label="Clear"
          type="button" 
          onClick={props.handleClearButton}
          style={buttonStyle}
          type="button" 
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>
      {/* Bottom Controls */}
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          right: "10px",
          zIndex: 99
        }}
      >
        <button 
          aria-label="Save"
          type="button" 
          onClick={props.handleSaveButton}
          style={buttonStyle}
        >
          <FontAwesomeIcon icon={faSave} />
        </button>
      </div>
    </Fragment>
  );

}