import React from 'react';
import PropTypes from 'prop-types';

const styles ={
    display: 'flex',
    // width: '90%',
    height: 40,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 15,
    fontFamily: "'Open Sans', sans-serif"
}

export const MeasurItTitleBar = () => {
    return (<div
        style={styles}
    >
       <img src="/Measureit-logo.png" alt={'measuring'} style={{height: 30, marginRight: '3vw'}} />
    </div>)
}

MeasurItTitleBar.propTypes = {
    mode: PropTypes.string.isRequired,
    measurement: PropTypes.number.isRequired
}