import { Box, Typography } from "@mui/material";
import React from "react";

const Intro = ({ short }) => {
  return (
    <>
      <Box sx={{ px: 2, pb: 0 }}>
        <Typography
          style={short ? { width: 225, fontSize: 12 } : { fontSize: 14 }}
        >
          Use your finger or cursor to color in the areas to be serviced. Use
          the marker, size, eraser and undo tools for fine tuning your
          selection.
          <br />
          <br />
          Save the estimate by clicking the quote button.
        </Typography>
        <Box sx={{ textAlign: "center" }}>
          {short ? null : (
            <img
              src="/measur.it-draw-undo.gif"
              alt="animate pan zoom"
              style={{ width: 200 }}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default Intro;
