import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";

export function GetQuoteButton(props) {
  const { measurement, mode, handleSaveButton } = props;
  const [display, setDisplay] = useState(
    `${Math.round(measurement)} ${mode === "area" ? "sq ft" : "linear ft"}`
  );

  useEffect(() => {
    setDisplay(
      `${Math.round(measurement.toFixed(2))} ${
        mode === "area" ? "sq ft" : "linear ft"
      }`
    );
  }, [measurement, mode]);

  return (
    <div
      style={{
        position: "fixed",
        bottom: "0px", //short ? "100px" : "20px",
        left: "0px",
        display: "flex",
        zIndex: 100,
        width: "100%",
        maxWidth: "940px",
        alignItems: "center",
        height: "70px",
        background: "#FFF",
        justifyContent: "center",
      }}
    >
        <Button
            onClick={handleSaveButton}
            variant="contained"
            color="primary"
          >
            Quote {display}
          </Button>
    </div>
  );
}
