import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearchPlus,
  faSearchMinus,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { Box, Button, useMediaQuery } from "@mui/material";
import json2mq from "json2mq";
import { shortQueries } from "../../queries";

/*
  This component renders custom control buttons over the
  estimator's interactive Google Map.

  This component takes the following props:

  * color
  * handleZoomInButton
  * handleZoomOutButton
  * handleLockMapButton
*/

export default function MapControls(props) {
  const short = useMediaQuery(json2mq(shortQueries));

  // Build styles for buttons
  const buttonStyle = {
    width: short ? "40px" : "50px",
    height: short ? "40px" : "50px",
    backgroundColor: "#FFF",
    border: "none",
    borderRadius: short ? "20px" : "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: short ? "20px" : "25px",
    color: props.color.iconActive,
    outline: "none",
    cursor: "pointer",
  };

  return (
    <Box
      style={{
        position: "fixed",
        bottom: "0px",
        zIndex: 100,
        display: "flex",
        justifyContent: "space-between",
        height: "70px",
        alignItems: "center",
        right: '0px',
        left: '0px',
        background: "#FFF",
        paddingRight: '6px'
      }}
    >
      <div style={{ display: "flex" }} className="map-tools-zoom">
        <button
          aria-label="Zoom in"
          type="button"
          onClick={props.handleZoomInButton}
          style={{
            ...buttonStyle,
          }}
        >
          <FontAwesomeIcon icon={faSearchPlus} />
        </button>
        <button
          aria-label="Zoom out"
          type="button"
          onClick={props.handleZoomOutButton}
          style={buttonStyle}
        >
          <FontAwesomeIcon icon={faSearchMinus} />
        </button>
      </div>
      <div className="map-tools-lock">
        <Button
          aria-label="Lock map"
          type="button"
          onClick={props.handleLockMapButton}
          variant="contained"
          color="primary"
        >
          Next
        </Button>
      </div>
    </Box>
  );
}
